




























































































import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default Vue.extend({
  name: "InvoiceTabApplyPrepayment",
  components: {
    InvoiceSelectPrepayment: () => import(/*webpackPrefetch: true */"./InvoiceSelectPrepayment.vue"),
  },
  data() {
    return {
      cacheInvoice: [],
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.invoiceStore.form,
      tabApplyPrepaymentSource: (st: any) => st.invoiceStore.tabApplyPrepaymentSource,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
    }),
    searchInvoiceBy(): string {
      return "customer.secureId~*" + this.form.customerId + "*_AND_" + "remainingAmount>0_AND_invoicePrepaymentStatus~UN_APPLIED";
    },
    isDetail(): boolean {
      return !!this.detailInvoice.status;
    },
  },
  created(): void {
    this.cacheInvoice = this.tabApplyPrepaymentSource;
  },
  methods: {
    ...mapMutations({
      setTabApplyPrepaymentSource: "invoiceStore/SET_TAB_APPLY_PREPAYMENT_SOURCE",
    }),
    ...mapActions({
      calcProRatePricing: "invoiceStore/CALC_PRO_RATE_PRICING_V2",
    }),
    commitStore(): void {
      this.setTabApplyPrepaymentSource(this.tabApplyPrepaymentSource);
    },
    deleteRow(record): void {
      const idx = this.tabApplyPrepaymentSource.findIndex(x => x.no === record.no);
      if (idx === -1) return;
      this.tabApplyPrepaymentSource.splice(idx, 1);
      this.tabApplyPrepaymentSource.forEach((d, idx) => {
        d.no = idx + 1;
      });
      this.commitStore();
      this.calcProRatePricing();
    },
    addRow(): void {
      const { tabApplyPrepaymentSource } = this;
      const newRow = {
        no: tabApplyPrepaymentSource.length + 1,
        invoicePrepaymentNumber: undefined as string | undefined,
        invoicePrepaymentNumberId: "",
        amount: 0,
        description: "",
        remainingAmount: 0,
        key: tabApplyPrepaymentSource.length,
        id: null,
        manual: true,
      };
      this.setTabApplyPrepaymentSource([...tabApplyPrepaymentSource, newRow]);
    },
    onSelectInvoicePrepayment({ meta }, r): void {
      r.remainingAmount = meta.remainingAmount || 0;
      r.amountApplied = meta.remainingAmount || 0;
      r.invoicePrepaymentNumberId = meta.id || null;
      r.amount = meta.amount || 0;
      r.description = meta.description || null;

      this.commitStore();
      this.calcProRatePricing();
    },
  }
});
