var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.isDetail
        ? _c(
            "div",
            { staticClass: "p-3" },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.addRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.tabApplyPrepaymentSource,
            scroll: { y: 320, x: "calc(520px + 50%)" }
          }
        },
        [
          _c(
            "a-table-column",
            { key: "no", attrs: { "data-index": "no", width: "75px" } },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_number_short")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "invoicePrepaymentNumber",
              attrs: { "data-index": "invoicePrepaymentNumber" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      record.manual
                        ? _c("InvoiceSelectPrepayment", {
                            attrs: { "prop-search-by": _vm.searchInvoiceBy },
                            on: {
                              "on-select": function(e) {
                                return _vm.onSelectInvoicePrepayment(e, record)
                              }
                            },
                            model: {
                              value: record.invoicePrepaymentNumber,
                              callback: function($$v) {
                                _vm.$set(record, "invoicePrepaymentNumber", $$v)
                              },
                              expression: "record.invoicePrepaymentNumber"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              _vm._s(record.invoicePrepaymentNumber || "-")
                            )
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_invoice_prepayment_number")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "amount",
              attrs: { "data-index": "amount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("currency")(record.amount)))
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_amount")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "description",
              attrs: { "data-index": "description" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      record.manual
                        ? _c("a-textarea", {
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              "auto-size": { minRows: 1, maxRows: 4 }
                            },
                            on: { change: _vm.commitStore },
                            model: {
                              value: record.description,
                              callback: function($$v) {
                                _vm.$set(record, "description", $$v)
                              },
                              expression: "record.description"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(record.description || "-"))
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_description")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "action",
              attrs: { align: "center", fixed: "right", width: "100px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return _c("a-icon", {
                      staticClass: "icon-delete",
                      attrs: { type: "delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteRow(record)
                        }
                      }
                    })
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_action")))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }